.container {
  background-color: hsl(env(--color-support-black));
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.main {
  background-color: hsl(env(--color-support-white));
  padding: env(--space-96) env(--space-40);
  border-radius: env(--radius-8);
  width: 90%;
}

.content {
  width: 100%;
}

.companies {
  width: 100%;
}

.company {
  background-color: hsl(env(--color-support-white));
  padding: env(--space-24);
  cursor: pointer;
  box-shadow: 0px 10px 40px rgba(15, 15, 15, 0.12);
  border-radius: env(--radius-10);
  width: 100%;
}

.icon {
  block-size: env(--space-48);
  inline-size: env(--space-48);
  border-radius: calc(env(--space-48) / 2);
  border: env(--space-2) solid hsl(env(--color-grey-20));
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  padding: env(--space-12);
  color: hsl(env(--color-support-white));
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: env(--space-60);
  inline-size: env(--space-60);
  background-color: hsl(env(--color-support-burgundy));
  border-radius: env(--radius-10);
}

@media (min-width: env(--breakpoint-small)) {
  .main {
    width: 100%;
    max-width: calc(env(--space-200) * 3);
  }

  .content {
    width: 100%;
    max-width: calc(env(--space-200) * 2);
  }
}
